var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();



        // bounding.top >= 0 &&
        // bounding.left >= 0 &&
        // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        // bounding.right <= (window.innerWidth || document.documentElement.clientWidth)

		if( window.innerWidth > 1024 ) {

			return ( bounding.top >= 0 && bounding.bottom <= ( ( window.innerHeight || document.documentElement.clientHeight ) * 1.5 ) );

		} else {

			return (  bounding.top >= 0 && bounding.bottom <= ( ( window.innerHeight || document.documentElement.clientHeight ) * 2 ) );
		}

};

export default isInViewport;
