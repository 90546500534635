import Cookies from 'js-cookie';

var _cookie = ( function( ){

    return {

        policycheck : function() {


            var _d = document.domain;
            var _c = Cookies.get( _d+'__cookie-approved' );

            if( _c != 'true' ) {

                $('body').addClass('cookie-notice');

                $('*[toggle-cn="accept"]').click( function(e){

                    $('body').removeClass('cookie-notice');

                    // -- set general cookie approved
                    Cookies.set( _d+'__cookie-approved', 'true', { expires: 365 });

                    _cookie.add_scripts( ['GTM-N8CQDCG', 'facebook'] );

                    e.preventDefault();
                });

            }

            if( _c == 'true' ) {

                _cookie.add_scripts( ['GTM-N8CQDCG', 'facebook'] );
            }

        },



        add_scripts : function( _args ) {

            function filter_for_ga( _check ) {

                var _v = false;

                $.grep( _check, function( n, i ) { if( n.indexOf('UA-') > -1 ){ _v = n; } }, true );
                $.grep( _check, function( n, i ) { if( n.indexOf('GTM-') > -1 ){ _v = n; } }, true );

                return( _v );
            }


            if ( filter_for_ga( _args ) ) {

                // -- go google analytics
                // -- add this to head of page: <script async src="https://www.googletagmanager.com/gtag/js?id={trackin-id}"></script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', filter_for_ga( _args ) );
            }

            if ( $.inArray( 'addthis', _args ) > -1 ) {

                if( _old_explorer_check() == false ) {
                    //--addthis
                    // -- don't forget to remove addthis form header
                    var addthisScript = document.createElement('script');
                    addthisScript.setAttribute('src', 'https://s7.addthis.com/js/300/addthis_widget.js#domready=1')
                    document.body.appendChild(addthisScript)
                }
            }

            if ( $.inArray( 'facebook', _args ) > -1 ) {

                //--addthis
                // -- don't forget to remove addthis form header
                fbq('consent','grant');
            }

        }
    }

})();

export default _cookie;
