var _submit_shim = (function() {

  return {

    init: function() {

        $('*[data-submit]').click(function(e){

            $('*[data-submit-target]').submit();
            e.preventDefault();
        });
    }
  }
})();

export default _submit_shim;
