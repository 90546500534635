import isInViewport from './../rp-booted/_is_in_viewport.js';

var _scroller = ( function() {

    return  {

        _bp: 979,

        init: function () {

            var _this = this;

            if(  document.getElementsByClassName('fixable')[0] ) {

                if( window.innerWidth > _scroller._bp  ) {

                    _scroller.fix_header();

                } else if ( window.innerWidth < _scroller._bp ) {

                    _scroller.fix_smheader();
                }
            }

			if ( document.querySelector('.cbk-lint') ) {

				if( window.innerWidth > _scroller._bp  ) {

					var cbkl      = window.addEventListener('scroll', function() {

						var _y      = $(window).scrollTop();

						if( _y > window.innerHeight / 3 ) {

							document.body.classList.add('hide-lint');

						} else {

							document.body.classList.remove('hide-lint');
						}
					});
				}
			}

            _scroller.revealer();
        },

        fix_header : function ( ) {

            var _y      = $(window).scrollTop();
            var _h      = document.getElementsByClassName('fixable')[0];
            var _f      = document.getElementsByClassName('filters__contents')[0];
            var _b      = document.getElementsByClassName('page-body')[0];
            var _tel    = document.getElementsByClassName('form-dd')[0];
            var _top    = _tel.getBoundingClientRect().top - 30;

            var ll      = window.addEventListener('scroll', function() {
                _y      = $(window).scrollTop();

                if( _y > _top ) {

                    _h.classList.add('fixed');
                    _h.style.top = ( ( _h.offsetHeight * - 1 ) + ( _f.offsetHeight + 90 ) )+'px';
                    _b.style.marginTop  =  _h.offsetHeight+'px';

                } else {

                    _h.classList.remove('fixed');
                    _h.style.top        = '0px';
                    _b.style.marginTop  =  '0px';
                }
            });

        },

        fix_smheader : function () {

            var _y      = $(window).scrollTop();
            var _h      = document.getElementsByClassName('fixable')[0];
            var _tel    = document.getElementsByClassName('filters__toggle')[0];
            var _top    = _tel.getBoundingClientRect().top - 105 ;

            var sl      = window.addEventListener('scroll', function() {

                _y      = $(window).scrollTop();

                if( _y > _top ) {

                    _h.classList.add('fixed');

                } else {

                    _h.classList.remove('fixed');
                }
            });
        },


        revealer : function () {

            var _r      = document.getElementsByClassName( 'revealer' );
            var _d      = document.getElementsByClassName( 'decorator' );
            var _s      = window.onscroll = function() {

                _scroller.checkElements( _r, _d );
            }

            _scroller.checkElements( _r, _d );
        },


        checkElements : function ( _r, _d ) {


            for ( var i = 0; i < _r.length; i++) {

                if( isInViewport( _r[i] ) ) {

                    _r[i].classList.add('revealed');
                }
            }

            for ( var i = 0; i < _d.length; i++) {


                if( isInViewport( _d[i] ) ) {

                    _d[i].classList.add('decorated');
                }
            }
        }
    }
})();

export default _scroller;
