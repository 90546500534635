var _video = ( function() {

	return {

		init_vimeo: function() {

			$('*[play-vid]').click(function(e){

				var player = new Vimeo.Player( $(this).closest('.block--video').find('iframe').first() );
				var overlay = $(this).closest('.block--video');

				overlay.addClass('fadin')
				player.play();

				setTimeout(function () {

					overlay.remove('fadin');
					overlay.addClass('playin');

				}, 500 );

				e.preventDefault();
			});
		},

		init_youtube: function() {

			$('*[yt-video]').each(function(i){

				var _id = $(this).attr('id');
				var _obj = $(this);

				player = new YT.Player( _id, {
					height: '315',
					width: '560',
					videoId: _obj.attr('yt-video'),
					playerVars: {
						'modestbranding': true, 'rel': 0, 'showinfo': 0, 'playsinline': 1
					},
					events: {
						'onReady': onPlayerReady
					}
				});

				function onPlayerReady(e) {

					var _id = e.target.playerInfo.videoData.video_id;
					init__vid_controls( $('#yt--'+_id).parent().parent().find('*[play-vid]') );
				}

				function init__vid_controls( _me ){

					_me.click(function(e){

						var player 	= YT.get( 'yt--'+$(this).attr('play-vid') );
						var overlay = $(this).closest('.block--video');

						overlay.addClass('fadin');
						player.playVideo();

						setTimeout(function () {

							overlay.remove('fadin');
							overlay.addClass('playin');

						}, 500 );

						e.preventDefault();
					});
				}
			});
		},
	}
})();

export default _video;
