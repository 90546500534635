import axios from 'axios';
import _img from './../rp-booted/_img.js';

Vue.component('item__entry', {

    props: ['e', 'i'],
    //todo: let template live inside html document and pull it in by ID
    template: '#item__entry',
		data: function () {
			return {
				iconImage: null,
			}
		},
    mounted: async function() {
      var _iconImage = await import('@src/images/svg/'+this.e.main_icon+'.svg');
      this.iconImage = _iconImage.default;
    }
});



if( document.getElementById( 'app--items' ) ) {

	var _events = new Vue({

		el: '#app--items',
		data: function () {
			return {

				debug: false,
				errors: false,
				overview: false,
				no_results: false
			}
		},

		methods: {


			loadLive: function() {

				var _this = this;
				var _r    = document.getElementById('app--items');

				if( document.documentElement.lang == 'en'  ) {

					var _root =  _refs['root'].replace("/en/", "");
					var _lang = 'eng';

				} else {

					var _root =  _refs['root'];
					var _lang = 'nl';
				}


				var _s  	= _r.dataset.serie;
				var _url    = _root+'wp-json/wp/v2/items?lng='+_lang+'&s='+_s;
                if (document.querySelector('.dev')) {
                    console.log(_url);
                }
				axios.get( _url )
				.then( function( response ) {

					if( response.data.count == 0 ) {

						_this.no_results 	= true;
						document.querySelector('.overview').classList.add('empty');

					} else {

						_this.overview  = response.data.entries;

					}

					console.dir(_this.overview);

					// todo, do this when page is really loaded
					setTimeout(function () {

						_img.init();
						// document.getElementsByClassName('element-loader')[0].remove();

						// var _r      = document.getElementsByClassName( 'revealer' );
						// var _d      = document.getElementsByClassName( 'decorator' );

						// _scroller.checkElements( _r, _d );
						// _hover_shim.init();


					}, 500 )
				});
			}
		},

		mounted: function () {

			var _this = this;
			if ( _this.debug ) {


			} else {

				_this.loadLive();
			}
		},

	});
}
