import axios from 'axios';
import _scroller from './_scroller.js';

Vue.component('agenda__entry', {

	props: ['entry'],
	//todo: let template live inside html document and pull it in by ID
	template: '#agenda__entry',
});



if( document.getElementById('app--calendar-highlights')) {

	var _events = new Vue({

		el: '#app--calendar-highlights',
		data: function () {
			return {

				errors: false,
				calendar: false,
				no_results: false,
				t: 'all',
				c: '-1',
				y: 'all',
				today: false
			}
		},

		methods: {

			loadLive: function () {

				var _this   = this;

				if( document.documentElement.lang == 'nl'  ) {

					var _root =  _refs['root'];
					var _lang = 'nl';

				} else {

					var _root =  _refs['root'].replace('/en//', '/' );
				 	var _lang = 'eng';
				}

				var _r      = document.querySelector('#app--calendar-highlights');
				var	_p_id	= '';

				if( _r.getAttribute('data-page-id') ) {

					_p_id = _r.getAttribute('data-page-id')
				}

				var _url = _root+'wp-json/wp/v2/calendar-highlight?lng='+_lang+'&id='+_p_id;

				if( _this.debug ) {

					console.log( _url );
				}

				axios.get( _url )
				.then( function( response ) {

					if( response.data.count == 0 ) {

						_this.no_results 	= true;

					} else {

						_this.no_results 	= false;
						_this.calendar = response.data.entries;
					}

					setTimeout(function () {

						// if( response.data.count > 0 ) { document.querySelector('.agenda-list').classList.remove('unload') }

						var _r      = document.getElementsByClassName( 'revealer' );
						var _d      = document.getElementsByClassName( 'decorator' );

						_scroller.checkElements( _r, _d );

					}, 500 );
				});
			},

			toggleDrop: function ( e ) {

				document.querySelector('.form-dd').classList.toggle('open-drop');
				e.preventDefault();
			},
 		},


		mounted: function () {

			var _this = this;

			if( document.querySelector('.dev') ) {

				_this.debug = true;
			}

			_this.loadLive();

		},

	});
}
