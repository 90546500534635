import axios from 'axios';
import _scroller from './_scroller.js';

Vue.component('agenda__entry', {

    props: ['entry'],
    //todo: let template live inside html document and pull it in by ID
    template: '#agenda__entry',
});




if( document.getElementById('app--calendar')) {

    var _events = new Vue({

        el: '#app--calendar',
        data: function () {
            return {

                errors: false,
                calendar: false,
				no_results: false,
                t: 'all',
                c: '-1',
                y: 'all',
				today: false
            }
        },

        methods: {


            loadLive: function () {

                var _this   = this;

                // get vars to load
                var _r      = document.querySelector('#app--calendar');
                if( _r.getAttribute('data-calendar-count') ) {

                    _this.c = _r.getAttribute('data-calendar-count');
                }
				if( document.documentElement.lang == 'nl'  ) {


					var _root =  _refs['root'];
					var _lang = 'nl';

				} else {

					var _root =  _refs['root'].replace('/en//', '/' );
				 	var _lang = 'eng';
				}

				if( this.today ) {

					// console.log(_lang);
					// var _url    = ( _refs['root'].replace( '/en/', '/' ) )+'static/_calendar_today.json';

					// why not everywhere this way?
					if ( _lang == 'nl' ) {

						var _url            = _root+'wp-json/wp/v2/debug_calendar?lng='+_lang;

					} else {

						var _url            = _root+'en/wp-json/wp/v2/debug_calendar?lng='+_lang;
					}

				} else {

					var _url    = _root+'wp-json/wp/v2/calendar?type='+_this.t+'&year='+_this.y+'&count='+_this.c+'&lng='+_lang;

				}

				if( document.querySelector('.dev') ) {

					console.log( _url );

				};

                axios.get( _url )
                .then( function( response ) {

					if( response.data.count == 0 ) {

						_this.no_results 	= true;

					} else {

						_this.no_results 	= false;
						_this.calendar = response.data.entries;
					}

                    setTimeout(function () {

                        if( response.data.count > 0 ) { document.querySelector('.agenda-list').classList.remove('unload') }
                        var _r      = document.getElementsByClassName( 'revealer' );
                        var _d      = document.getElementsByClassName( 'decorator' );

                        _scroller.checkElements( _r, _d );

                    }, 500 );
                });

				// console.log(_url);
            },

            toggleDrop: function ( e ) {

                document.querySelector('.form-dd').classList.toggle('open-drop');

                e.preventDefault();
            },


            filterCalYear: function ( _y, e ) {

                this.y = _y;
                document.querySelector('.form-dd').classList.remove('open-drop');
                document.querySelector('.agenda-list').classList.add('unload');

                document.querySelector('.form-dd__current .text').innerHTML = this.y;

                // -- scroll
                var _move_down    = new TimelineLite();
                _move_down.to( window, 0.67, { scrollTo: { y:'.agenda-list', offsetY: 150 } , ease: Power4.easeOut }, '.32' );


                this.loadLive();

                // close filter on sm screen
                var _t = document.getElementsByClassName('filters__toggle')[0];
                var _f = document.getElementsByClassName('filters__contents')[0];

                _t.classList.add('active');
                _f.classList.remove('active');

                e.preventDefault();
            },


            filterCalType: function ( _t, e ) {

                this.t = _t;
                document.querySelector('.agenda-list').classList.add('unload');
                document.querySelector('.form-radio__option.checked').classList.remove('checked');
                e.target.closest('li').classList.add('checked');

                // -- scroll
                var _move_down    = new TimelineLite();
                _move_down.to( window, 0.5, { scrollTo: { y:'.agenda-list', offsetY: 150 } , ease: Power4.easeOut }, '.32' );

                this.loadLive();

                // close filter on sm screen
                var _t = document.getElementsByClassName('filters__toggle')[0];
                var _f = document.getElementsByClassName('filters__contents')[0];

                _t.classList.add('active');
                _f.classList.remove('active');

                e.preventDefault();
            },


            calSearch: function( e ) {

                // this.searchterm =
            },

			checkForToday: function( e ) {

				var _this = this;
				if( document.querySelector('.page--list') ) {

						var _app = document.querySelector('.page--list');
						if ( _app.dataset.view == 'today') {

							_this.today = true;
						}
				}

			}
         },



        mounted: function () {

            var _this = this;

            if( _this.debug ) {



            } else {

				_this.checkForToday();
                _this.loadLive();
            }
        },

    });
}
