$(function() {

    $('a[attr="#"]').click(function(e) {

        if (!$(this).hasClass('fancybox')) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if ($(this).hasClass('home')) {
                $('html,body').animate({
                    scrollTop: (0)
                }, 750, 'easeInOutExpo', function() {});
                return false;
            } else {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);

                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: ((target.offset().top) - 45)
                        }, 750, 'easeInOutExpo', function() {});
                        return false;
                    }
                }
            }
        }

        e.preventDefault();
    });
});