import Flickity from 'flickity';

var _slider = ( function() {

    return {

		flkty : false,
        check : function() {

			if( document.querySelector('.related-slider.active') ) {

				var _g = document.querySelector('.related-slider.active');
				_slider.set_gallery( _g );
			}
        },


        set_gallery: function( _c ) {
            if( window.innerWidth < 980 ) {
				_slider.flkty = new Flickity( _c.querySelector('.main-slider'), {

                    cellAlign: 'left',
                    // freeScroll: true,
					percentPosition: false,
                    wrapAround: true,
                    contain: true,
                    lazyLoad: 4,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false
                });

            } else {

                _slider.flkty = new Flickity( _c.querySelector('.main-slider'), {

                    cellAlign: 'left',
                    wrapAround: true,
                    groupCells: 2,
                    lazyLoad: 2,
                    selectedAttraction: 0.15,
                    friction: 0.76,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false
                });
            }

            _slider.flkty.on( 'lazyLoad', function( event, cellElement ) {

				// console.log('lazyload');
                cellElement.classList.add('loaded-slide')
            });

            _c.querySelector('.next').addEventListener( 'click', function( e ){

                _slider.flkty.next( true, false );
                e.preventDefault();
            });

        },

		clear_gallery: function( _c ) {

			_slider.flkty.destroy();
		},

        resizeDesc : function( ) {

            var _desc;
            var _c = 0;
            var _l = $('.slider__entry').length;

            $('.slider__entry-img img').load(function(){

                _desc = $(this).closest('.slider__entry-flex').find('.slider__entry-desc');
                $(this).width( _desc.width() );

                if( _c == ( _l - 2 )  ) {

                    $('.gallery-slider').slick('slickGoTo', 0);

                } else {

                    _c++;
                }
            });
        },


        set_page_gallery : function( ) {
            _slider.flkty = new Flickity( document.querySelector('.page-gallery.lg'), {

                cellAlign: 'left',
                autoPlay: true,
                // contain: true,
                adaptiveHeight: true,
                wrapAround: true,
                // groupCells: 1,
                // lazyLoad: 2,
                // selectedAttraction: 0.15,
                // friction: 0.76,
                imagesLoaded: true,
                prevNextButtons: false
            });
        },

		set_page_gallery__sm : function() {

			_slider.flkty = new Flickity( document.querySelector('.page-gallery.sm'), {

                cellAlign: 'left',
                autoPlay: true,
                // contain: true,
                adaptiveHeight: true,
                wrapAround: true,
                // groupCells: 1,
                // lazyLoad: 2,
                // selectedAttraction: 0.15,
                // friction: 0.76,
                imagesLoaded: true,
                prevNextButtons: false
            });
		},

		set_article_gallery : function( _me ) {

			if( window.innerWidth > 979 ) {

				// var _adapt = false;

			} else {

				// var _adapt = true;
			}

			_slider.flkty = new Flickity( _me, {

			    cellAlign: 'center',
			    autoPlay: true,
			    // contain: true,
			    adaptiveHeight: false,
			    wrapAround: false,
			    // groupCells: 1,
			    // lazyLoad: 2,
			    // selectedAttraction: 0.15,
			    // friction: 0.76,
			    imagesLoaded: true,
			    prevNextButtons: false,
          on: {
            ready: function() {
              let observer = new IntersectionObserver(function (entries, observer) {
                for (let entry of entries) {
                  if (entry.isIntersecting) {
                    let maxHeight = 0;
                    let entries = _me.querySelectorAll('.slider-entry');
                    if (entries.length > 0) {
                      entries.forEach((entry) => {
                        if (maxHeight < entry.clientHeight) {
                          maxHeight = entry.clientHeight;
                        }
                      });

                      _me.querySelector('.flickity-viewport').style.height = `${maxHeight}px`;
                    }
                  }
                }
              });

              observer.observe(_me);

              // setTimeout(() => {
              //   let maxHeight = 0;
              //   let entries = _me.querySelectorAll('.slider-entry');
              //   if (entries.length > 0) {
              //     entries.forEach((entry) => {
              //       if (maxHeight < entry.clientHeight) {
              //         maxHeight = entry.clientHeight;
              //       }
              //     });

              //     _me.querySelector('.flickity-viewport').style.height = `${maxHeight}px`;
              //   }
              // }, 1000);
            }
          },
			});
		}
	}
})();

export default _slider;
