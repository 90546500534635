var _readmore = ( function() {

	return {

		init: function( ) {

			if ( document.querySelector('.read-more') ) {

				document.querySelector('.read-more').addEventListener( 'click', function( e ){

					var _c  = document.querySelector('.article__postcontent');
					var _p 	= document.querySelector('.article__postcontent-wrap');
					var _h  = _p.offsetHeight;

					e.target.classList.add( 'clicked' );
					_c.classList.add( 'active' );
					_c.style.height = _h+'px';

					e.preventDefault();

				});
			}
		},


		// -- todo: make modular
		init_credits: function() {

			if ( document.querySelector('.article-head__credits-more') ) {

				document.querySelector('.article-head__credits-more').addEventListener( 'click', function( e ){

					var _c  = document.querySelector('.article-head__credits-inner');
					var _p  = _c.querySelector('p');

					var _h  = _p.offsetHeight;

					if(  document.querySelector( '.article-head__credits-inner.active' ) ) {

						e.target.classList.remove( 'clicked' );
						_c.classList.remove( 'active' );
						_c.style.height = '0px';

					} else {

						e.target.classList.add( 'clicked' );
						_c.classList.add( 'active' );
						_c.style.height = _h+'px';
					}



					e.preventDefault();

				});
			}
			if ( document.querySelector('.article-body__credits-more') ) {

				document.querySelector('.article-body__credits-more').addEventListener( 'click', function( e ){

					var _c  = document.querySelector('.article-body__credits-inner');
					var _p  = _c.querySelector('p');

					var _h  = _p.offsetHeight;

					if(  document.querySelector( '.article-body__credits-inner.active' ) ) {

						e.target.classList.remove( 'clicked' );
						_c.classList.remove( 'active' );
						_c.style.height = '0px';

					} else {

						e.target.classList.add( 'clicked' );
						_c.classList.add( 'active' );
						_c.style.height = _h+'px';
					}



					e.preventDefault();

				});
			}
		}

	}

})();

export default _readmore;
