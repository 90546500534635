var _sb_checkbox = ( function() {

	return {

		init: function() {

			document.addEventListener('click', toggleActive, false );
			function toggleActive( e ) {

				if (e.target !== e.currentTarget) {

					if ( e.target.classList.contains('gfield_consent_label') || e.target.classList.contains('ginput_container_consent') ) {

						e.target.closest('.ginput_container').classList.toggle('active');

						// -- add checkbox toggle on top of gravity forms, seperate from toggle from label
						if ( e.target.classList.contains('ginput_container_consent') ) {

							if( e.target.closest('.ginput_container').classList.contains('active') ) {

								e.target.parentElement.querySelector('input[type=checkbox]').checked = true;

							} else {

								e.target.parentElement.querySelector('input[type=checkbox]').checked = false;
							}
						}

						e.stopPropagation;
					}
				}

			}
		}
	}
})();

export default _sb_checkbox;
