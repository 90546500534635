import moment from 'moment';
import Rellax from 'rellax';
import svg4everybody from 'svg4everybody';
import 'flickity';

import './rp-booted/_scroll-to-div.js';
import './rp-booted/_polyfills.js';
import './rp-booted/_mobile_window_height.js';

import _scroller from './modules/_scroller.js';
import _filter from './modules/_filter.js';
import _readmore from './modules/_readmore.js';
import _vimeo from './modules/_wrap_vimeo.js';
import _footer from './modules/_footer.js';
import _slider from './modules/_slider.js';
import _video from './modules/_video.js';

import './modules/_search.js';
import './modules/_vue.calendar-highlight.js';
import './modules/_vue.calendar.js';
import './modules/_vue.updates.js';
import './modules/_vue.items-overview.js';
import './modules/_vue.overview.js';
import './modules/_vue.series-overview.js';
import './modules/_vue.sidebar.js';
import './modules/_vue.slider.js';

import _hover_shim from './rp-booted/_hover-shim.js';
import _submit_shim from './rp-booted/_submit-shim.js';
import _sb_checkbox from './rp-booted/_sb_checkbox.js';
import _cookie from './rp-booted/_cookie.js';
import _img from './rp-booted/_img.js';

import infoImage from '@src/images/info.png';

var _bp = 979;

// for development: vue included in vendors, for production remove here and add production version in func--front.php

// localize date functions
moment.locale('nl');
for (var i = 0; i < document.body.classList.length; i++) {

    if( document.body.classList[i] == 'lang--en'){

        moment.locale('en');
    }
}


// -- check fo IE11
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if ( isIE11 ) {

    $('body').addClass('IE11')

	var _t = 'Unsupported browser';
	var _b = '<p>tentrotterdam.nl won\'t work great in older browsers</p><p>We prefer supporting more recent versions of major web-browsers like <a href="https://www.google.com/chrome/" target="_blank">Chrome</a>, <a href="https://www.mozilla.org/en-US/exp/firefox/new/" target="_blank">Firefox</a>, <a href="https://support.apple.com/en-us/HT204416" target="_blank">Safari</a> and <a href="https://support.microsoft.com/en-us/help/4501095/download-the-new-microsoft-edge-based-on-chromium" target="_blank">Edge</a>.</p>';

    $('body').prepend('<div class="nogo-modal-wrap"><div class="nogo-modal"><h1 class="std-title"><img src="'+infoImage+'" alt="'+_t+'" width="30" height="30" /><span class=text>'+_t+'</span></h1><div class="txt">'+_b+'</div></div></div>');

}


// -- loadpage
setTimeout(function () {

    document.body.classList.add('ready');

}, 500 );


// -- site specific
_scroller.init();

if( document.getElementsByClassName('filters__contents').length > 0 ) {

    _filter.init();
}

document.querySelector('.site-overlay').addEventListener('click',function( e ){

    document.body.classList.toggle('sidebar--open');

    // close subnavs
    if( document.getElementsByClassName('open-sub').length > 0 ) {

        document.getElementsByClassName('open-sub')[0].getElementsByClassName('sidebar__subnav')[0].style.height = '0px';
        document.getElementsByClassName('open-sub')[0].classList.remove('open-sub');
    }
    e.preventDefault();
});

if( window.innerWidth > 1023 ) {

    var rellax = new Rellax('.rellax');
}

// -- general data-trigges
_submit_shim.init();
_hover_shim.init();
_sb_checkbox.init();
_readmore.init();
_readmore.init_credits();

if( document.querySelector('.article-body__col.txt iframe') ) {

	_vimeo.wrap();
}

// -- jquery
$(document).ready(function(){

    // _newsletter.init();
    _cookie.policycheck();
    _footer.check();
    svg4everybody();


    if ( document.querySelector('.page-gallery') ) {

		if( window.innerWidth > 980 ) {

			_slider.set_page_gallery();

		} else {

			_slider.set_page_gallery__sm();
		}

		// -- fix flickety performance
		var tapArea, startX;
		var tapArea = document.querySelectorAll('.main-slider');
		var startX = 0;
    }

    if ( document.querySelector('.block--slider') ) {

		var _articleSliders = document.querySelectorAll( '.block--slider' );
		for (var i = 0; i < _articleSliders.length; i++) {

			_slider.set_article_gallery( _articleSliders[i] );
		}


		// -- fix flickety performance
		var tapArea, startX;
		var tapArea = document.querySelectorAll('.main-slider');
		var startX = 0;

    }



	$('.top-shim').mouseenter( function( ){

		$(this).next('.brand-logo').addClass( 'hover' );

	});

	$('.top-shim').mouseleave( function( ){

		$(this).next('.brand-logo').removeClass( 'hover' );

	});

	//--

    setTimeout(function () {

        // fade-out elment
        if( document.querySelector( '.fader' ) ) {
            var _f = document.querySelector( '.fader' );
            _f.classList.add('faded');
        }

    },500 );

	_img.respond();

	//--

	if( $('.vimeo-contents').length > 0 ){

        _video.init_vimeo();
    }
});

window.onYouTubeIframeAPIReady = function() {

	if( $('.yt-contents').length > 0 ){
		_video.init_youtube();
	}
}

window.addEventListener( 'resize', function() {

	if ( document.querySelector('.page-gallery') ) {

		if( window.innerWidth > 980 ) {

			_slider.set_page_gallery();

		} else {

			_slider.set_page_gallery__sm();
		}

		// -- fix flickety performance
		var tapArea, startX;
		var tapArea = document.querySelectorAll('.main-slider');
		var startX = 0;
	}

	if ( document.querySelector('.block--slider') ) {

		_slider.set_article_gallery();

		// -- fix flickety performance
		var tapArea, startX;
		var tapArea = document.querySelectorAll('.main-slider');
		var startX = 0;

	}
});
