var _filter= ( function() {


    return  {

        init: function () {

            var _t = document.getElementsByClassName('filters__toggle')[0];
            var _c = document.getElementsByClassName('filters__closelink')[0];
            var _f = document.getElementsByClassName('filters__contents')[0];

            _t.addEventListener( 'click', function( e ){

                _t.classList.toggle('active');
                _f.classList.toggle('active');

                e.preventDefault();
            });

            _c.addEventListener( 'click', function( e ){

                _t.classList.toggle('active');
                _f.classList.toggle('active');

                e.preventDefault();
            });

        }
    }
})();

export default _filter;
