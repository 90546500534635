import playImage from '@src/images/svg/play.svg';
import playHoverImage from '@src/images/svg/play--hover.svg';

var _vimeo= ( function() {

    return  {

        wrap: function () {

			var _scr 		= document.createElement('script');
			_scr.src 		= 'https://player.vimeo.com/api/player.js';

			_scr.onload = function () {

				_vimeo.theme();
			};


			document.head.appendChild( _scr );


        },

		theme: function () {

			var _iframe 	= document.querySelector('.article-body__col.txt iframe');

			// -- wrap
			var _wrapper 	= document.createElement('div');
			_wrapper.classList.add('video-panel');
			_iframe.parentNode.insertBefore( _wrapper, _iframe );
			_wrapper.appendChild( _iframe );

			// -- access vimeo object
			var _player 	= new Vimeo.Player( _iframe );

			// -- fill the panel with video
			$('.video-panel').fitVids();

			// --
			var _overlay    = document.createElement('span');
			_overlay.classList.add('video-overlay');

			var _btn 		= document.createElement('a');
			_btn.classList.add('video-play');
			_btn.href 		= "#"

			if( document.body.classList[i] == 'lang--en'){

				_btn.innerHTML = '<span class="icon"><image src="'+playImage+'" /><image class="icon__hover" src="'+playHoverImage+'" /></span><span class="text">play video</span>';

		    } else {

				_btn.innerHTML = '<span class="icon"><image src="'+playImage+'" /><image class="icon__hover" src="'+playHoverImage+'" /></span><span class="text">speel video</span>';
			}

			_btn.addEventListener( 'click', function( e ){

				_wrapper.classList.add('active');
				_player.play();
				e.preventDefault()
			});


			_overlay.appendChild( _btn );
			_wrapper.appendChild( _overlay );
		}
    }
})();

export default _vimeo;
