import axios from 'axios';
import _scroller from './_scroller.js';
import _hover_shim from './../rp-booted/_hover-shim.js';

Vue.component('overview__entry', {

    props: ['entry'],
    //todo: let template live inside html document and pull it in by ID
    template: '#overview__entry',
});



if( document.getElementById('app--overview')) {

    var _events = new Vue({

        el: '#app--overview',
        data: function () {
            return {

                debug: false,
                errors: false,
                overview: false,
                no_results: false
            }
        },

        methods: {


            loadLive: function() {

                var _this = this;
                var _r    = document.getElementById('app--overview');
                var _t    =  _r.getAttribute('data-load-type');


				if( document.getElementsByClassName('lang--nl').length > 0  ) {


					var _root =  _refs['root'];
					var _lang = 'nl';

				} else {

					var _root =  _refs['root'].replace('/en//', '/' );
				 	var _lang = 'eng';
				}
                if( _t == 'updates'  ) {

                    var _url            = _root+'wp-json/wp/v2/'+_t+'?group=current&lng='+_lang;

                }

                if( _t == 'programme'  ) {

                    var _url            = _root+'wp-json/wp/v2/'+_t+'?group=current&lng='+_lang;

                }

                if( _t == 'shows'  ) {

                    var _url            = _root+'wp-json/wp/v2/'+_t+'?group=current&lng='+_lang;

                }

                if( _t == 'events'  ) {

                    var _url            = _root+'wp-json/wp/v2/'+_t+'?group=current&lng='+_lang;
                }
				if ( document.querySelector('.dev') ) {

					console.log( _url );
				}

                axios.get( _url )
                .then( function( response ) {


                	if( response.data.count == 0 ) {

						_this.no_results 	= true;
						document.querySelector('.overview').classList.add('empty');

					} else {

						_this.overview  = response.data.entries;
					}

					setTimeout(function () {

						document.getElementsByClassName('element-loader')[0].remove();

						var _r      = document.getElementsByClassName( 'revealer' );
						var _d      = document.getElementsByClassName( 'decorator' );

						_scroller.checkElements( _r, _d );
						_hover_shim.init();

					}, 500 )
                });
            }
        },

        mounted: function () {

            var _this = this;
            if ( _this.debug ) {


            } else {

                _this.loadLive();
            }
        },

    });
}
