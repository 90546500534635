if( document.getElementById('app--sidebar')) {

    var _events = new Vue({

        el: '#app--sidebar',
        data: function () {
            return {

                errors: ''
            }
        },

        methods: {

            // --  helper: get closest
            getClosest: function (elem, selector) {

                if (!Element.prototype.matches) {
                    Element.prototype.matches =
                    Element.prototype.matchesSelector ||
                    Element.prototype.mozMatchesSelector ||
                    Element.prototype.msMatchesSelector ||
                    Element.prototype.oMatchesSelector ||
                    Element.prototype.webkitMatchesSelector ||
                    function(s) {
                        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                        while (--i >= 0 && matches.item(i) !== this) {}
                        return i > -1;
                    };
                }

                for ( ; elem && elem !== document; elem = elem.parentNode ) {
                    if ( elem.matches( selector ) ) return elem;
                }

                return null;

            },


            // -- toggleMainNav
            toggleNav: function( e ) {
                document.body.classList.toggle('sidebar--open');

                // close subnavs
                if( document.getElementsByClassName('open-sub').length > 0 ) {
                    document.getElementsByClassName('open-sub')[0].getElementsByClassName('sidebar__subnav')[0].style.height = '0px';
                    document.getElementsByClassName('open-sub')[0].classList.remove('open-sub');
                }

				if( document.querySelector('.backlink.hidden') ) {
					// document.querySelector('.backlink.hidden').classList.remove('hidden');
				}

				if( document.querySelector('.sidebar__triggers.hide') ) {

					document.querySelector('.sidebar__triggers.hide').classList.remove('hide');
				}

				e.preventDefault();
			},


            // -- toggleSubNav
            toggleSub: function( e ) {

                if( String(e.target.className).indexOf('sublink') == -1  ) {

                    var _c  = this.getClosest( event.target, '.topitem' );
                    var _s  = _c.getElementsByClassName('sidebar__subnav')[0].getElementsByClassName('subitem');
                    var _h  = 0;

                    for (var i = 0; i < _s.length; i++) {

                        _h +=  _s[i].getBoundingClientRect().height;
                    }


                    if(  _c.className.indexOf('open-sub') == -1 ) {

                        if( document.getElementsByClassName('open-sub').length > 0 ) {

                            document.getElementsByClassName('open-sub')[0].getElementsByClassName('sidebar__subnav')[0].style.height = '0px';
                            document.getElementsByClassName('open-sub')[0].classList.remove('open-sub');

                        }

                        _c.getElementsByClassName('sidebar__subnav')[0].style.height = _h+'px';
                        // document.querySelector('.sidebar .backlink').classList.add('hidden');

                    }  else {

                        _c.getElementsByClassName('sidebar__subnav')[0].style.height = '0px';
                        // document.querySelector('.sidebar .backlink').classList.remove('hidden');
                    }

                    _c.classList.toggle('open-sub');

                    // hide triggers on md screens
                    if( window.innerWidth <  980 ) {

                        _t = document.getElementsByClassName('sidebar__triggers')[0];

                        if(  _c.className.indexOf('open-sub') == -1 ) {

                            _t.classList.remove('hide');

                        } else {

                            _t.classList.add('hide');
                        }
                    }

                    e.preventDefault();
                }
            }
        },


        mounted: function () {


        },

    });
}
