var _footer = ( function() {

    return {

        check : function() {

            _footer.set_triggers();
            _footer.set_height();
        },


        set_height : function() {

            var _h = document.querySelector( '.site-footer .pre-content' ).offsetHeight;
            document.querySelector( '.site-footer .form-content' ).style.height = _h+'px';
        },


        set_triggers : function() {

            document.querySelector('a[data-trigger="toggle"]').addEventListener('click',function( e ){

                // var _h = document.querySelector( '.site-footer .form-content form' ).offsetHeight;
                // document.querySelector( '.site-footer .form-content' ).style.height = _h+'px';
                TweenMax.to( document.querySelector( '.site-footer .form-content' ), 1.5, { height:"auto", ease:Power2.easeOut });
                e.target.closest( '.content-toggler' ).classList.add('active');
                e.preventDefault();
            });
        }
    }
})();

export default _footer;
