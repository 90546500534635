var _img = ( function() {

	return {

		init: function() {

			if ( document.querySelector( '*[data-srcset]' ) ) {

				_img.respond();

				window.onresize = function(event) {

					// check for width for ios bug
					_img.respond();
				}
			}
		},

		respond: function() {

            $('*[data-srcset]').each( function(){

                var _imgs =  $(this).attr('data-srcset').split(',');

                switch( true ){

                    case ( $(window).width() > 1366 ):

                        if( $(this).is('img') ){

                            $(this).attr( 'src', _imgs[2] );

                        }else{

                            $(this).css( 'background-image', 'url("'+_imgs[2]+'")' );
                        }

                        break;

                    case ( $(window).width() > 660 ):

                        if( $(this).is('img') ){

                            $(this).attr( 'src', _imgs[1] );

                        }else{

                            $(this).css( 'background-image', 'url("'+_imgs[1]+'")' );
                        }

                        break;

                    default:

                        if( $(this).is('img') ){

                            $(this).attr( 'src', _imgs[0] );

                        }else{

                            $(this).css( 'background-image', 'url("'+_imgs[0]+'")' );
                        }
                }
            });
        }

	}

})();

export default _img;
