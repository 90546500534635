var _hover_shim = ( function() {

	return {

		init: function() {

			var _dc = document.querySelectorAll('[data-hover]');
			for ( var i = 0; i < _dc.length; i++ ) {

				_dc[i].addEventListener('mouseenter', function( e ){

					this.closest( this.getAttribute('data-hover') ).classList.add('hover');
				});

				_dc[i].addEventListener('mouseleave', function( e ){

					this.closest( this.getAttribute('data-hover') ).classList.remove('hover');
				});
			}
		}
	}
})();

export default _hover_shim;
