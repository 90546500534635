import axios from 'axios';
import _slider from './_slider.js';
import _img from './../rp-booted/_img.js';

Vue.component('slider__entry', {

    props: ['entry'],
    //todo: let template live inside html document and pull it in by ID
    template: '#slider__entry',
});



if( document.getElementById('app--sliders')) {

	var _events = new Vue({

		el: '#app--sliders',
		data: function () {
			return {

				errors: false,
				gallery_past: false,
				gallery_up: false,
				gallery_to: false
			}
		},

		methods: {

			loadLive: function() {

				var _this 		= this;
				var _r    		= document.getElementsByClassName('related-slider')[0];
				var _t    		= _r.getAttribute('data-slider-type');

				if ( document.querySelector('.related-slider.upcoming') ) {

					var _url_up    = _refs['root']+'wp-json/wp/v2/'+_t+'s?group=upcoming';

					if ( document.querySelector('.dev') ) {

						console.log( _url_up );
					}

					axios.get( _url_up )
					.then( function( response ) {

					 	if( response.data.entries === undefined || response.data.entries === null || response.data.entries.length == 0 ) {
							if( document.querySelector('.related-slider.upcoming') ) {

								document.querySelector('.related-slider.upcoming').remove();
							}

							document.querySelector('.related-slider.past').classList.add('active');
							return;

						} else {

							_this.gallery_up = response.data.entries;
							setTimeout(function () { _slider.check(); }, 500 )
						}
					});
				}

				if( document.querySelector('.related-slider.past')  ) {

	                var _url_past  = _refs['root']+'wp-json/wp/v2/'+_t+'s?group=past&highlight=true';

					if ( document.querySelector('.dev') ) {

						console.log( _url_past );
					}

					if( _r.getAttribute( 'data-educatie-id' ) ) { _url_past += '&educatie_cat='+_r.getAttribute( 'data-educatie-id' ) }

					axios.get( _url_past )
	                .then( function( response ) {
						if( response.data.entries.length == 0 ) {

							if( document.querySelector('.related-slider.past') ) {

								document.querySelector('.related-slider.past').remove();
							}
							return;

						} else {

							_this.gallery_past = response.data.entries;
							setTimeout(function () { _slider.check(); }, 500 )
						}
	                });
				}

				// -- tent-online

				if( document.querySelector('.related-slider.tent-online') ) {

					var _post_id 	=  document.querySelector('.related-slider.tent-online').dataset.postId;
					var _url  		= _refs['root']+'wp-json/wp/v2/items?related_show='+_post_id;

					if ( document.querySelector('.dev') ) {

						console.log( _url );
					}

					axios.get( _url ).then( function( response ) {

						_this.gallery_to = response.data.entries;
						setTimeout(function () {

							_img.respond();
							_slider.check();
						}, 500 );
					});
				}
			},


			setToggler: function() {

				$('.related-slider__toggle').click( function( e ) {

					if( $('.related-slider.active').length > 0 ){

						_slider.clear_gallery();
						var _old 		= document.querySelector('.related-slider.active');
						_old.classList.remove('active');
					}


	                $(this).closest('.related-slider').toggleClass('active');

					if( window.innerWidth < 980 ) {

						var _offsetY = 70;

					} else {

						var _offsetY = 0;
					}

	                var _open_up    = new TimelineLite();
	                _open_up.to( window, 0.67, { scrollTo: { y:'.related-slider.active', offsetY: _offsetY } , ease: Power4.easeOut }, '.32' );

					var _new = document.querySelector('.related-slider.active');


				 	_slider.set_gallery( _new );

	                e.preventDefault();
	            });

			}
        },

        mounted: function () {

            var _this = this;
            _this.loadLive();
			_this.setToggler();
        },

    });
}
